import React from 'react';
import Header from './Header';
import Footer from './Footer';
import gameboardImage from '../assets/images/GameBoard.jpg';
import '../styles/GameboardTimeline.css';

const GameboardTimeline = () => {
  return (
    <div className="gameboard-timeline">
      <Header />
      <main className="gameboard-timeline__main">
        <div className="gameboard-timeline__header">
          <h1 className="gameboard-timeline__title">My Life Timeline</h1>
        </div>
        <div className="gameboard-timeline__instructions">
          <h2 className="gameboard-timeline__subtitle">Explore Beth's Journey:</h2>
          <p className="gameboard-timeline__description">
            Click on each age to discover important milestones and health updates from that year.
          </p>
        </div>
        <div className="gameboard-timeline__board">
          <img src={gameboardImage} alt="Life Timeline Gameboard" className="gameboard-timeline__image" />
        </div>
      </main>
      <Footer className="gameboard-timeline__footer" backgroundColor="#desiredColor" />
    </div>
  );
};

export default GameboardTimeline;
