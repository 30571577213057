import React from 'react';
import PropTypes from 'prop-types';

const ICON_SIZES = {
  small: 16,
  medium: 24,
  large: 48
};

const Icon = ({ 
  name,
  type = 'web', // 'web' or 'social'
  color = '#191716',
  size = 'medium', // 'small', 'medium', or 'large'
  className = '',
  onClick
}) => {
  // Helper function to get the correct icon path
  const getIconPath = () => {
    if (type === 'social') {
      return `/src/assets/icons/SocialIcons/Social=${name}.svg`;
    }
    return `/src/assets/icons/WebIcons/🟡 Icon=${name}.svg`;
  };

  // Get pixel size from size prop
  const pixelSize = ICON_SIZES[size];

  return (
    <img
      src={getIconPath()}
      alt={`${name} icon`}
      width={pixelSize}
      height={pixelSize}
      style={{ color }}
      className={className}
      onClick={onClick}
    />
  );
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['web', 'social']),
  color: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default Icon;
