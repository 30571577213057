import React from 'react';
import Header from './Header';
import HeroImage from './HeroImage';
import Footer from './Footer';
import '../styles/HomePage.css';

const HomePage = () => {
  return (
    <div className="home-page">
      <Header />
      <main className="home-page__main">
        <HeroImage />
      </main>
      <Footer className="home-page__footer" backgroundColor="#40e0d0" />
    </div>
  );
};

export default HomePage;
