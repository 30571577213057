import React from 'react';
import '../styles/Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faPinterestP, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

const Footer = ({ className, backgroundColor }) => {
  return (
    <footer className={`footer ${className}`} style={{ backgroundColor }}>
      <div className="footer__content">
        <div className="footer__left">
          <h3 className="footer__title">Thanks for listening</h3>
          <p className="footer__description">
            If you want to follow more or learn more about my design work and AI programming skills or all the other random things follow me here on these...
          </p>
          <div className="footer__social">
            <a href="#" aria-label="Facebook"><FontAwesomeIcon icon={faFacebookF} className="footer__social-icon" /></a>
            <a href="#" aria-label="Instagram"><FontAwesomeIcon icon={faInstagram} className="footer__social-icon" /></a>
            <a href="#" aria-label="LinkedIn"><FontAwesomeIcon icon={faLinkedinIn} className="footer__social-icon" /></a>
            <a href="#" aria-label="Pinterest"><FontAwesomeIcon icon={faPinterestP} className="footer__social-icon" /></a>
          </div>
        </div>
        <div className="footer__form">
          <form>
            <input type="text" placeholder="Name" className="footer__input" />
            <input type="email" placeholder="Email" className="footer__input" />
            <textarea placeholder="Type your message here" className="footer__textarea"></textarea>
            <button type="submit" className="footer__submit">Contact Me</button>
          </form>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
