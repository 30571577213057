import React from 'react';
import '../styles/HeroImage.css';

const HeroImage = () => {
  return (
    <div className="hero-image">
      <div className="hero-image__container">
        <img 
          src="/src/assets/images/HeroImage.jpg" 
          alt="Hero" 
          className="hero-image__media"
        />
      </div>
    </div>
  );
};

export default HeroImage;