import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import '../styles/NavButton.css';

const NavButton = ({ 
  label,
  variant = 'pink',
  to,
  className = ''
}) => {
  const buttonClasses = `
    nav-button
    nav-button-${variant}
    ${className}
  `.trim();

  return (
    <Link to={to} className={buttonClasses}>
      {label}
    </Link>
  );
};

NavButton.propTypes = {
  label: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['pink', 'cyan', 'green', 'yellow', 'orange']),
  to: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default NavButton;
