import React, { useRef, useState } from 'react';
import HTMLFlipBook from 'react-pageflip';
import brandGuidelinesPdf from '../assets/pdf/Umi Wellness Brand Guidelines.pdf';
import membershipHandbookPdf from '../assets/pdf/Umi Wellness Membership Handbook_By Beth.pdf';
import '../styles/RachelPage.css';

const FlipBook = ({ pdfUrl, title }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const book = useRef();

  const onPage = (e) => {
    setCurrentPage(e.data);
  };

  return (
    <div className="flip-book-container">
      <h2>{title}</h2>
      <div className="flip-book-wrapper">
        <HTMLFlipBook
          width={550}
          height={733}
          size="stretch"
          minWidth={315}
          maxWidth={1000}
          minHeight={400}
          maxHeight={1533}
          maxShadowOpacity={0.5}
          showCover={true}
          mobileScrollSupport={true}
          onFlip={onPage}
          className="demo-book"
          ref={book}
        >
          <iframe
            src={pdfUrl}
            title={title}
            width="100%"
            height="100%"
            style={{ border: 'none' }}
          />
        </HTMLFlipBook>
      </div>

      <div className="controls">
        <button onClick={() => book.current.pageFlip().flipPrev()}>
          Previous
        </button>
        <span>
          Page {currentPage + 1} of {totalPage}
        </span>
        <button onClick={() => book.current.pageFlip().flipNext()}>
          Next
        </button>
      </div>
    </div>
  );
};

const RachelPage = () => {
  return (
    <div className="rachel-page">
      <FlipBook 
        pdfUrl={brandGuidelinesPdf}
        title="Umi Wellness Brand Guidelines"
      />
      <FlipBook 
        pdfUrl={membershipHandbookPdf}
        title="Umi Wellness Membership Handbook"
      />
    </div>
  );
};

export default RachelPage;
