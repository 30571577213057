import React from 'react';
import { Link } from 'react-router-dom';
import NavButton from './NavButton';
import Icon from './Icon';
import '../styles/Header.css';

const Header = () => {
  return (
    <header className="header">
      <div className="header__top-bar" />
      <div className="header__main">
        <Link to="/" className="header__brand">
          <span>BethCNC</span>
        </Link>

        <nav className="header__nav">
          <NavButton 
            label="About Me" 
            to="/about" 
            variant="pink" 
          />
          <NavButton 
            label="Timeline" 
            to="/timeline" 
            variant="cyan" 
          />
          <NavButton 
            label="Resources" 
            to="/resources" 
            variant="green" 
          />
          <NavButton 
            label="Images" 
            to="/images" 
            variant="yellow" 
          />
          <NavButton 
            label="Rachel" 
            to="/rachel" 
            variant="orange" 
          />
        </nav>

        <Link to="/" className="header__home-button">
          <Icon 
            name="Home"
            type="web"
            size="medium"
            color="#171717"
            className="header__home-icon"
          />
        </Link>
      </div>
    </header>
  );
};

export default Header;