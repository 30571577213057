// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './styles/global.css';  // Global styles
import HomePage from './components/HomePage';  // Make sure this is the correct import
import GameboardTimeline from './components/GameboardTimeline';
import RachelPage from './components/RachelPage'; // Add this import

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/timeline" element={<GameboardTimeline />} />
        <Route path="/rachel" element={<RachelPage />} /> {/* Add this route */}
      </Routes>
    </Router>
  );
}

export default App;
